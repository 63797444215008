<template>
  <div class="list">
    <div class="ziHead">
      <img :src="indexImg" style="width: 100%; height: 100%" />
      <!-- <img v-else src="../assets/image/partyBuilding.jpg" style="width: 100%; height: 100%" /> -->
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span :class="{onActive : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index"  class="spanActive">
            <a class="navMainInner"
              v-if="!item.child.length && item.url && item.url !== ''&& item.entTChannelUuid !='01150003' " 
              target="_blank" 
              :href="item.url">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="!item.child.length && (!item.url || item.url == '') || item.entTChannelUuid =='01150003'" 
              :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/list/${item.entTChannelUuid}`">
              {{item.name}}
            </a>
            <!-- <a 
              v-if="item.child.length">
              {{item.name}}
            </a> -->
            <a class="navMainInner"
              v-if="item.child.length && item.child[0].url && item.child[0].url !== '' "  
              :href="item.child[0].url">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="item.child.length && (!item.child[0].url || item.child[0].url == '') && item.entTChannelUuid !='011500050003' " 
              :href="item.child[0].catalog === 1 ? `/listPage/${item.child[0].entTChannelUuid}` : item.child[0].catalog === 2 ? `/list/${item.child[0].entTChannelUuid}` : `/list/${item.child[0].entTChannelUuid}`">
              {{item.name}}
            </a>
            <a class="navMainInner"
              v-if="item.child.length && (!item.child[0].url || item.child[0].url == '') && item.entTChannelUuid =='011500050003' " 
              :href="`/list/011500050003`">
              {{item.name}}
            </a>
          </span>
        </div>
        <div class="navMainRight">
          <div style="float: right">
            <span>
              <router-link to="/">
                <i class="iconfont">&#xe689;</i> 
                <span> &nbsp; 首页 &nbsp;>&nbsp; </span>
              </router-link>
            </span>
            <span v-if="viewSlideHdParent" class="txt1"><a :href="slideHdParentUrl">{{slideHdParentName}}</a> </span> 
            <span v-if="slideHdName2!=''" class="txt2"> &nbsp;>&nbsp; {{slideHdName2}} </span>
            <span v-if="isthird" class="txt3">&nbsp; > {{slideHdName1}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="listMain">
      <div class="listContainer">
        <ul class="newList">
          <li v-for="(item, index) in listData" :key="index">
            <a class="clearfloat" v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
              <div class="time fl">
                <h3 class="">{{ dayjs(item.publishDate).format('DD') }}</h3>
                <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
              </div>
              <div class="inner">
                <h3 class="single-line">{{ item.title }}</h3>
                <p class="multi-line1">{{ item.artDesc | ellipsis}}</p>
              </div>
            </a>
            <a class="clearfloat" v-else-if="(!item.url || item.url == '') && item.entTChannelUuid =='00190001'" :href="`/detail/01150003/${item.entTArticleUuid}`">
              <div class="time fl">
                <h3 class="">{{ dayjs(item.publishDate).format('DD') }}</h3>
                <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
              </div>
              <div class="inner">
                <h3 class="single-line">{{ item.title }}</h3>
                <p class="multi-line1">{{ item.artDesc | ellipsis}}</p>
              </div>
            </a>
            <a class="clearfloat" v-else-if="(!item.url || item.url == '') && active == '011500050003' " :href="`/detail/011500050003/${item.entTArticleUuid}`">
              <div class="time fl">
                <h3 class="">{{ dayjs(item.publishDate).format('DD') }}</h3>
                <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
              </div>
              <div class="inner">
                <h3 class="single-line">{{ item.title }}</h3>
                <p class="multi-line1">{{ item.artDesc | ellipsis}}</p>
              </div>
            </a>
            <a class="clearfloat" v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
              <div class="time fl">
                <h3 class="">{{ dayjs(item.publishDate).format('DD') }}</h3>
                <p>{{ dayjs(item.publishDate).format('YYYY-MM') }}</p>
              </div>
              <div class="inner">
                <h3 class="single-line">{{ item.title }}</h3>
                <p class="multi-line1">{{ item.artDesc | ellipsis}}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="pagin">
        <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        :pager-count="6"
        @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {getChildChannel,getChannelInfo,articles, getAllArtListByParentUuid} from '@/api/common'
export default {
  data() {
    return {
    istwo: true,
    isthird: false,
    listData: [],
    slideHdName1: '',
    slideHdName2: '',
    slideHdTitle: '',
    viewSlideHdParent: true,
    slideHdParentName: '',
    slideHdParentUrl: '',
    slideHdName: '',
    pageCont: '',
    active: '',
    slideHd: {},
    slideList:[],
    pageSize: 10,
    pageNum: 1,
    total: 0,
    indexImg: '',
    }
  },
  mounted(){
  var _this = this
  _this.active = _this.$route.params.id
  _this.getChannelInfo()
  _this.articles()
  },
  filters: {
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 150) {
        return value.slice(0, 150) + '...'
      }
      return value
    },
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let params1 = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(params1).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          if( data.parentUuid == "0" ){
            _this.slideHdParentName = data.name
            if(data.fileName && data.fileName !== ''){
              _this.indexImg = data.fileName
            }
            else{
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          } else {
            _this.getChildChannel(data.parentUuid)
          }
          _this.slideHdName1 = data.name

          let params2 = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params2).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              if (data.parentUuid == '0') {
                // _this.getChildChannel(data.entTChannelUuid)
                _this.slideHdParentName = data.name
                _this.slideHdName2 = _this.slideHdName1
                _this.slideHdTitle = _this.slideHdName2
                if(data.fileName && data.fileName !== ''){
                  _this.indexImg = data.fileName
                }
                else{
                  _this.indexImg = require('../assets/image/partyBuilding.jpg')
                }
                _this.isthird = false
              } else {
                _this.isthird = true
                // _this.getChildChannel(data.parentUuid)
                _this.slideHdName2 = data.name

                let params3 = {
                  channelUuid: data.parentUuid
                }
                getChannelInfo(params3).then(res => {
                  const { code, data } = res
                  if (code === 1 && data) {
                    _this.slideHdParentName = data.name
                    _this.slideHdTitle = _this.slideHdName1
                    // _this.indexImg = data.fileName
                    // _this.getChildChannel(data.parentUuid)
                  }
                })
              }
            }
          })
        }
      })
    },
    // 获取列表
      articles() {
        var _this = this
        let id = ''
        var applicationId = ''
        if (_this.$route.params.id == '01150003') {
          id = '00190001'
          applicationId = _this.webIdGroup
        } else {
          id = _this.$route.params.id
          applicationId = _this.webId
        }
        let data = {
          entTApplicationUuid: applicationId,
          channelUuid: id,
          pageNum: _this.pageNum,
          pageSize: _this.pageSize,
          // entTApplicationUuid:_this.webId
        }
        if( _this.$route.params.id=="011500050001" || _this.$route.params.id=="011500050002" || _this.$route.params.id=="011500050003"){
          getAllArtListByParentUuid(data).then(res => {
            const {
              code,
              data
            } = res
            if (code === 1 && data) {
              _this.listData = data.rows
              _this.total = data.totalRows
            }
          })
        }else{
          articles(data).then(res => {
            const {
              code,
              data
            } = res
            if (code === 1 && data) {
              _this.listData = data.rows
              _this.total = data.totalRows
            }
          })
        }
      },
    currentChange(e) {
        var _this = this
        _this.pageNum = e
        _this.articles()
      }
    },
  }
</script>

<style>
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.list{
  margin-top: 72px;
}
.list .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  /* background: url(../assets/image/business2.jpg) center no-repeat; */
  background-size: 100% 100%;
}
.ziNav {
    height: 42px;
    border-bottom: 1px solid #cacaca;
    margin-top: 15px;
    width: 100%!important;
}
.navMain {
    width: 1200px;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}
.list .navMainLeft {
    width: 880px;
    float: left;
}
.spanActive{
  display: inline-block;
  padding: 0 6px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.onActive a {
    border-bottom: 2px solid #0263b2;
}
.navMainInner:hover {
    border-bottom: 2px solid #0263b2;
}
.navMainRight {
    width: 320px;
    float: right;
    overflow: hidden;
    font-size: 13px;
    color: #a2a2a2;
    margin-top: 10px;
    line-height: 24px;
}
.navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.navMainRight span a{
    float: none;
    font-size: 13px;
    color: #a2a2a2;
    vertical-align: top;
}
.navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.listContainer{
    width: 1200px;
    margin: 20px auto 0;
}
.newList{
    width: 100%;
    padding: 0;
}
.newList li {
    width: 100%;
    height: 164px;
    overflow: hidden;
    border-top: 1px solid #f1f1f1;
}
.newList li .time {
    width: 109px;
    height: 105px;
    overflow: hidden;
    background-color: #f2f2f2;
    margin-top: 34px;
    padding: 10px;
    box-sizing: border-box;
    color: #666;
    text-align: center;
}
.newList li .time h3 {
    height: 24px;
    overflow: hidden;
    font-size: 26px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
}
.newList li .time p {
    height: 24px;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.newList li .inner {
    width: 1070px;
    height: 105px;
    overflow: hidden;
    margin-top: 34px;
    background-color: #e8e9ee;
    padding: 10px;
    box-sizing: border-box;
    margin-left: 125px;
}
.newList li .inner h3 {
    height: 33px;
    overflow: hidden;
    line-height: 33px;
    font-size: 14px;
}
.newList li .inner p {
    height: auto;
    overflow: hidden;
    color: #6e706d;
    font-size: 13px;
    line-height: 27px;
}
.multi-line, .multi-line1 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: clip;
    overflow: hidden;
}
.newList li a:hover .time {
    color: #fff;
    background-color: #0162b3;
}
.newList li a:hover .inner,
.newList li a:hover .inner h3,
.newList li a:hover .inner p {
    color: #fff; 
}
.newList li a:hover .inner {
     transition: all .3s ease-in-out;
     background-color: #0162b3;
}
.pagin {
  margin-top: 20px;
}
.pagin .el-pagination {
  text-align: center;
}
@media (max-width: 1300px) {
  .listContainer{
    width: 1000px;
  }
  .newList li .inner {
    width: 874px;
  }
  .navMain{
    width: 1000px;
  }
  .list .navMainLeft {
    width: auto;
}
  .navMainRight {
    width: auto;
  }
  .list{
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  .list .ziHead {
    height: 362px;
  }
  .navMain {
    width: 96%;
  }
  .list .navMainLeft {
    width: auto;
}
  .navMainRight {
    width: auto;
  }
  .listContainer{
    width: 96%;
  }
  .newList li .inner {
    width: calc(100% - 120px);
  }
  .list{
    margin-top: 0px;
  }
}
@media (max-width: 768px) {
  .list .ziHead {
    height: 200px;
  }
  .list{
    margin-top: 0px;
  }
  .navMainRight{
    display: none;
  }
  .listContainer{
    width: 94%;
  }
  .navMain {
    width: 96%;
  }
  .list .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}
.newList {
    width: 100%;
}
.newList li {
  height: auto;
  padding: 10px 0;
}
.newList li .time {
    width: 58px;
    padding: 2px;
    margin: 0;
    height: 82px;
}
.newList li .inner {
    margin: 0;
    padding: 6px;
    height: 82px;
    float: right;
    width: calc(100% - 62px);
}
.newList li .time h3 {
    font-size: 20px;
    margin-top: 16px;
}
.newList li .time p {
    font-size: 12px;
    white-space: nowrap;
}
.newList li .inner h3 {
    height: 30px;
    line-height: 30px;
}
.newList li .inner p {
    max-height: 36px;
    font-size: 12px;
    line-height: 18px;
}
  /* .newList {
    margin-top: 4px;
  }
  .newList ul li .pull-left {
    width: 58px;
    padding: 2px;
    margin: 0;
    height: 82px;
  }
  .newList ul li .pull-left .u {
    font-size: 20px;
    margin-top: 16px;
  }
  .newList ul li .pull-left .d {
    font-size: 12px;
    white-space: nowrap;
  }
  .newList ul li .pull-right {
    width: calc(100% - 62px);
  }
  .newList ul li .pull-right .content {
    max-height: 36px;
    font-size: 12px;
    line-height: 18px;
  }
  .newList ul li .pull-right {
    margin: 0;
    padding: 6px;
    height: 82px;
  }
  .newList ul li .pull-right .t {
    height: 30px;
    line-height: 30px;
  }
  .newList ul li {
    height: auto;
    padding: 10px 0;
  }  */
}
</style>